<template>
  <div>
    <div class="flex items-center gap-2 overflow-x-auto px-4">
      <mini-product v-for="(product, index) in products" :key="`product-${index}`"
                    :data="setEventsData(item)" :hide-tags="true"
                    :padding="8" :product="product"
                    @click="emit('close')">
        <template #product-name="{ title }">
          <div class="van-multi-ellipsis--l2 text-xs font-niceone-bold text-mirage pt-1">{{ title }}</div>
        </template>
        <template #image>
          <div class="w-full h-27 pt-4">
            <niceone-image v-if="product.thumb" :src="product.thumb" class="w-18 h-18 mx-auto"/>
          </div>
        </template>
        <template #price>
          <price :increase-font-by="-2" :font-for-discount="-2" :minHeight="20" :product="product"
                 class="pt-2" hide-line-through/>
        </template>
        <template #cart-icon-button>
          <cart-icon-button :link="product.seo_url_en" :product="product" :source-data="setEventsData(item)" class="z-1"
                            is-mini-product/>
        </template>
      </mini-product>
    </div>
  </div>
</template>

<script setup>
import CartIconButton from "@/components/product/ProductIconCartButton";
import Price from "@/components/product/Price";
import MiniProduct from "@/components/product/MiniProduct";
import {EVENT_SOURCES} from "@/constants/events";

defineProps({
  products: {
    type: Array,
    default: () => []
  },
  item: {
    type: Object,
    default: () => {
    }
  },
})

const emit = defineEmits(['close'])

const setEventsData = (item) => {
  return {...EVENT_SOURCES.VIDEO_PAGE, source_name: item?.title_en, source_id: item?.id}
}
</script>