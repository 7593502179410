<template>
  <div class="m-3">
    <div class="flex mb-2">
      <div class="text-base font-niceone-medium">{{ $t('product.recommended_products') }}</div>
    </div>
    <grid-products :image-width="imageWidth" :products="items?.recommended_products?.products" hide-fav-btn
                   v-bind="$attrs" :source-data="sourceData"/>
  </div>
</template>

<script setup>
import {useCartStore} from '@/store/cart'
import GridProducts from "@/components/cart/GridProducts";


defineOptions({
  inheritAttrs: false
})

defineProps({
  imageWidth: {
    type: Number,
    default: 100
  },
  sourceData: {
    type: Object,
    default: {}
  }
})

const cartStore = useCartStore()
const {items} = storeToRefs(cartStore)
</script>