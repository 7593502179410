<template>
  <footer>
    <div class="text-white bg-secondary">
      <div v-if="isInfo" class="custom-container p-4">
        <div class="flex justify-center lg:items-center lg:justify-between gap-6 lg:gap-5 flex-col lg:flex-row">
          <div class="flex flex-col gap-2">
            <h5 class="text-xl">{{ $t('footer.helpdesk.title') }}</h5>
            <h6 class="text-footer text-[16px]">
              {{ $t('footer.helpdesk.description') }}
            </h6>
          </div>
          <div class="flex items-center">
            <div class="flex items-center gap-3">
              <div class="flex items-center justify-center bg-ftIcon h-12 w-12 rounded-full">
                <niceone-image :src="$publicPath('/images/helpcenter.svg')"/>
              </div>
              <div class="flex gap-2 flex-col">
                <h6 class="text-footer text-[16px]">{{ $t('footer.helpdesk.helping_center') }}</h6>
                <a class="text-[22px] text-[#feaebb]" href="https://help.niceonesa.com"
                   rel="noopener" target="_blank"
                   @click="onHelpSection('Helping Center')">help.niceonesa.com</a>
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <div class="flex items-center gap-3">
              <div class="flex items-center justify-center bg-ftIcon h-12 w-12 rounded-full">
                <niceone-image :src="$publicPath('/images/email.svg')" height="32px" width="32px"/>
              </div>
              <div class="flex flex-col gap-2">
                <h6 class="text-footer text-[16px]">{{ $t('footer.helpdesk.email') }}</h6>
                <a class="text-[22px] text-[#feaebb]" href="mailto:cs@niceonesa.com" target="_blank"
                   @click="onHelpSection('Email')">cs@niceonesa.com</a>
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <div class="flex items-center gap-3">
              <div class="flex items-center justify-center bg-ftIcon h-12 w-12 rounded-full">
                <niceone-image :src="$publicPath('/images/phone.svg')" height="28px" width="28px"/>
              </div>
              <div class="flex flex-col gap-2">
                <h6 class="text-footer text-base">{{ $t('footer.helpdesk.direct_call') }}</h6>
                <a class="text-[22px] text-[#feaebb]" dir="ltr" href="tel:00966920033385"
                   target="_blank"
                   @click="onHelpSection('Direct Call')">+966 92 003 3385</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-[#fcfcfc] pt-6 sm:pt-[50px] px-4">
        <div class="custom-container pb-3">
          <div class="flex flex-col gap-5 lg:flex-row justify-between">
            <div class="hidden lg:flex flex-col space-y-[30px]">
              <h5 class="text-black text-lg font-bold">{{ $t('footer.popular_categories.popular_categories') }}</h5>
              <div class="flex items-center gap-4 flex-wrap">
                <h6 class="text-black text-sm group">
                  <nuxt-link :to="$routerUrl('makeup')"
                             class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                             @click="onBottomNavigation('makeup','Popular Categories')">
                    {{ $t('footer.popular_categories.makeup') }}
                  </nuxt-link>
                </h6>
                <h6 class="text-black text-sm group">
                  <nuxt-link :to="$routerUrl('perfume')"
                             class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                             @click="onBottomNavigation('perfume','Popular Categories')">
                    {{ $t('footer.popular_categories.perfume') }}
                  </nuxt-link>
                </h6>
                <h6 class="text-black text-sm group">
                  <nuxt-link :to="$routerUrl('care')"
                             class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                             @click="onBottomNavigation('care','Popular Categories')"
                  > {{ $t('footer.popular_categories.care') }}
                  </nuxt-link>
                </h6>
                <h6 class="text-black text-sm group">
                  <nuxt-link :to="$routerUrl('nails')"
                             class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                             @click="onBottomNavigation('nails','Popular Categories')"
                  > {{ $t('footer.popular_categories.nails') }}
                  </nuxt-link>
                </h6>
                <h6 class="text-black text-sm group">
                  <nuxt-link :to="$routerUrl('accessories')"
                             class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                             @click="onBottomNavigation('accessories','Popular Categories')"
                  > {{ $t('footer.popular_categories.accessories') }}
                  </nuxt-link>
                </h6>
                <h6 class="text-black text-sm group">
                  <nuxt-link :to="$routerUrl('lenses')"
                             class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                             @click="onBottomNavigation('lenses','Popular Categories')"
                  > {{ $t('footer.popular_categories.lenses') }}
                  </nuxt-link>
                </h6>
              </div>
            </div>
            <div class="hidden lg:flex flex-col space-y-[30px]">
              <h5 class="text-black text-lg font-bold">{{ $t('footer.niceone.title') }}</h5>
              <ul class="flex flex-col gap-2">
                <li>
                  <h6 class="text-black text-sm group">
                    <a :href="`https://niceonesa.com/${$i18n.locale}/blogs`"
                       class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                       target="_blank">
                      {{ $t('footer.information.links.blogs') }}
                    </a>
                  </h6>
                </li>
                <li>
                  <h6 class="text-black text-sm group">
                    <a :href="`https://group.niceonesa.com/${$i18n.locale}`"
                       class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                       target="_blank">
                      {{ $t('footer.information.links.group') }}
                    </a>
                  </h6>
                </li>
                <li>
                  <h6 class="text-black text-sm group">
                    <a :href="`https://careers.niceone.com/${$i18n.locale}`"
                       class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                       target="_blank" @click="onBottomNavigation('Careers','Niceone')">
                      {{ $t('footer.information.links.careers') }}
                    </a>
                  </h6>
                </li>
                <li>
                  <h6 class="text-black text-sm group">
                    <nuxt-link :to="$routerUrl('terms')"
                               class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                               @click="onBottomNavigation('Terms and conditions','Niceone')"
                    >{{ $t('footer.information.links.terms') }}
                    </nuxt-link>
                  </h6>
                </li>
                <li>
                  <h6 class="text-black text-sm group">
                    <nuxt-link :to="$routerUrl('refund')"
                               class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                               @click="onBottomNavigation('Return and refund policy','Niceone')"
                    >{{ $t('footer.information.links.return') }}
                    </nuxt-link>
                  </h6>
                </li>
                <li>
                  <h6 class="text-black text-sm group">
                    <nuxt-link :to="$routerUrl('privacy')"
                               class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                               @click="onBottomNavigation('Privacy','Niceone')"
                    > {{ $t('footer.information.links.privacy') }}
                    </nuxt-link>
                  </h6>
                </li>
              </ul>
            </div>
            <div class="hidden lg:flex flex-col space-y-[30px]">
              <h5 class="text-black text-lg font-bold">{{ $t('profile.profile') }}</h5>
              <ul class="flex flex-col gap-2">
                <li>
                  <h6 class="text-sm text-black group">
                    <nuxt-link :to="$routerUrl('profile')"
                               class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                               rel="nofollow"
                               @click="onBottomNavigation('Profile','Niceone')"> {{ $t('profile.profile') }}
                    </nuxt-link>
                  </h6>
                </li>
                <li>
                  <h6 class="text-sm text-black group">
                    <nuxt-link :to="$routerUrl('orders')"
                               class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                               rel="nofollow"
                               @click="onBottomNavigation('Orders','Profile')"> {{ $t('profile.orders') }}
                    </nuxt-link>
                  </h6>
                </li>
                <li>
                  <h6 class="text-sm text-black group">
                    <nuxt-link :to="$routerUrl('favourite')"
                               class="transition-all delay-[.2s] border-b-2 border-b-white pb-1 group-hover:border-b-2 group-hover:border-b-[#000]"
                               rel="nofollow"
                               @click="onBottomNavigation('Favourite','Profile')"> {{ $t('profile.favourites') }}
                    </nuxt-link>
                  </h6>
                </li>
              </ul>
            </div>
            <div class="flex flex-col space-y-[30px] items-center md:items-start">
              <h5 class="text-black text-lg font-bold">{{ $t('footer.connected') }}</h5>
              <ul class="flex lg:grid lg:grid-cols-3 gap-3">
                <li>
                  <a href="https://www.facebook.com/pg/niceonesa/about/"
                     rel="nofollow noopener" target="_blank"
                     @click="onBottomNavigation('Facebook','Stay Connected')">
                    <niceone-image :src="$publicPath('/images/facebk.svg')"
                                   class="h-10"/>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCu8c7LeOLpt1pPICrkHBKiw"
                     target="_blank" @click="onBottomNavigation('Youtube','Stay Connected')">
                    <niceone-image :src="$publicPath('/images/youtube.svg')" class="h-10"/>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/niceonesa/?hl=en"
                     target="_blank" @click="onBottomNavigation('Instagram','Stay Connected')">
                    <niceone-image :src="$publicPath('/images/insta.svg')" class="h-10"/>
                  </a>
                </li>
                <li>
                  <a href="https://www.snapchat.com/add/niceonesa"
                     target="_blank" @click="onBottomNavigation('Snapchat','Stay Connected')">
                    <niceone-image :src="$publicPath('/images/snap.svg')" class="h-10"/>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/NICEONESA" target="_blank"
                     @click="onBottomNavigation('Twitter','Stay Connected')">
                    <niceone-image :src="$publicPath('/images/x-twitter.svg')" class="h-10"/>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/niceonesa/"
                     rel="nofollow noopener" target="_blank"
                     @click="onBottomNavigation('Linkedin','Stay Connected')">
                    <niceone-image :src="$publicPath('/images/linkedin.svg')"
                                   class="h-10"/>
                  </a>
                </li>
              </ul>
            </div>
            <div class="flex flex-col items-center md:items-start space-y-[30px]">
              <h5 class="text-black text-lg font-bold">{{ $t('footer.shop') }}</h5>
              <ul class="flex flex-col items-center gap-3">
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.NiceOne.App"
                     rel="nofollow noopener" target="_blank"
                     @click="onBottomNavigation('Google play','Download our apps')">
                    <niceone-image :src="$publicPath('/images/play-store.svg')" width="150"/>
                  </a>
                </li>
                <li>
                  <a href="https://itunes.apple.com/sa/app/nice-one-%D9%86%D8%A7%D9%8A%D8%B3-%D9%88%D9%86/id1271754138?mt=8"
                     target="_blank"
                     @click="onBottomNavigation('App Store','Download our apps')">
                    <niceone-image :src="$publicPath('/images/app-store.svg')" width="150"/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="bg-white mt-3 py-[15px] border-t-[1px] border-t-[#ededed]">
          <div class="custom-container">
            <div class="flex justify-center md:justify-between items-center flex-wrap gap-3">
              <ul class="flex justify-center gap-1">
                <li>
                  <niceone-image :src="$publicPath('/images/mada.svg')" height="44px" width="68px"/>
                </li>
                <li>
                  <niceone-image :src="$publicPath('/images/visa.svg')" height="44px" width="68px"/>
                </li>
                <li>
                  <niceone-image :src="$publicPath('/images/master-card.svg')" height="44px" width="68px"/>
                </li>
                <li>
                  <niceone-image :src="$publicPath('/images/tamara.svg')" height="44px" width="68px"/>
                </li>
                <li>
                  <niceone-image :src="$publicPath('/images/apple-pay.svg')" height="44px" width="68px"/>
                </li>
                <li>
                  <niceone-image :src="$publicPath('/images/tamara-pay.svg')" height="44px" width="68px"/>
                </li>
                <li>
                  <niceone-image :src="$publicPath('/images/express-pay.svg')" height="44px" width="68px"/>
                </li>
                <li>
                  <niceone-image :src="$publicPath('/images/stc-pay.svg')" height="44px" width="68px"/>
                </li>
              </ul>

              <div class="flex justify-center items-center">
                <div class="px-3">
                  <p class="flex justify-center text-[#222] text-xs mb-1">
                    {{ $t('footer.rights', {year: currentYear}) }}
                  </p>
                  <p class="flex justify-center text-[#ababab] text-xs">
                    {{ $t('footer.vat') }}
                  </p>
                </div>
                <a href="https://maroof.sa/24519" rel="noopener">
                  <niceone-image :src="$publicPath('/images/footerlogo.webp')" height="30px"
                                 width="65px"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---->
  </footer>
</template>

<script setup>
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";

defineProps({
  isInfo: {
    type: Boolean,
    default: true
  }
})
const onHelpSection = (eventLabel) => {
  track(TRACKERS.HELP_SECTION, {
    eventLabel: eventLabel
  })
}
const onBottomNavigation = (eventLabel, eventAction) => {
  track(TRACKERS.BOTTOM_NAVIGATION, {
    eventLabel: eventLabel,
    eventAction: eventAction,
  })
}

const currentYear = new Date().getFullYear()
</script>

<style lang="scss" scoped></style>