<template>
  <div class="grid gap-2 grid-cols-3 pb-3">
    <product v-for="(product, index) in products" :key="`product-${index}`" :hide-fav-btn="hideFavBtn"
             :padding="8" :product="product" class="!min-w-[80px]" hide-reviews hide-tags
             :source-data="setEventsData(product)">
      <template #badge>
        <div v-if="product?.special[0]?.discount_ratio" class="absolute ltr:right-0 rtl:left-0 top-0 z-1 bg-pastel rounded-xl
              ltr:rounded-br-none rtl:rounded-bl-none text-white text-[10px] py-1 px-2">
          <span dir="ltr">{{ product?.special[0]?.discount_ratio }}</span>
        </div>
      </template>
      <template #image>
        <niceone-image :src="product.thumb" :width="imageWidth" class="mx-auto"/>
      </template>
      <template #price>
        <div :class="{'text-discount-color': !!product?.special?.length}" class="text-[13px] pt-1 font-niceone-medium">
          {{ product?.price_formated }}
        </div>
      </template>

    </product>
  </div>
</template>

<script setup>
const props = defineProps({
  products: {
    type: Array,
    default: () => []
  },
  hideFavBtn: {
    type: Boolean,
    default: false
  },
  imageWidth: {
    type: Number,
    default: 100
  },
  sourceData: {
    type: Object,
    default: {}
  }
})

const setEventsData = (product) => !props?.sourceData.ISPLP ? props?.sourceData : {
  source_type: props?.sourceData.source_type,
  source_name: product?.en_name,
  source_id: product.id
}
</script>

<style scoped>

</style>